import React from "react";
import DateTimePicker from "./pickers";

export default ({ onChange, culture, defaultDate, minDate }) => {
  return (
    <DateTimePicker
      onChange={onChange}
      culture={culture}
      defaultDate={defaultDate}
      minDate={minDate}
    />
  );
};

export const TimeOnly = ({ onChange, defaultDate, culture, minDate }) => {
  return (
    <DateTimePicker
      defaultValue={defaultDate || new Date()}
      type="time"
      onChange={onChange}
      culture={culture}
      minDate={minDate}
    />
  );
};

export const DateOnly = ({ culture, defaultDate, disablePast, ...rest }) => {
  const now = new Date();
  const conditionalProp = {};
  if (disablePast) conditionalProp.min = now;

  return (
    <DateTimePicker defaultValue={defaultDate || new Date()} type="date" />
  );
};
