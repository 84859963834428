import DateTime, { TimeOnly, DateOnly } from "./basic";
import AdvanceDateTime from "./advance";
import SimpleAdvance from "./simple-advance";

const momentLocalizer = require("./localizer");

export const DateTimePicker = DateTime;
export const DatePicker = DateOnly;
export const TimePicker = TimeOnly;
export const AdvanceDateTimePicker = AdvanceDateTime;
export const localization = momentLocalizer;
export const WithoutPopupDatePicker = SimpleAdvance;
