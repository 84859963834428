import React, { useState, useContext, useRef } from "react";
import { LanguageContext } from "../../lang";
import Popup from "./Popup/dependent-popup";
import AdvanceSelection from "./components/advance-selection";

const style = {
  container: {
    width: 90,
    background: "white",
    height: 44,
    borderRadius: 5,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "0 5px",
    marginRight: 10,
  },
  button: {
    display: "flex",
    alignItems: "center",
  },
  label: {
    fontSize: 12,
    marginLeft: 4,
    marginRight: 4,
  },
  icon: {
    color: "#ed5523",
  },
};
const popupPosition = {
  top: 0,
  left: 0,
};
const DateTimePickerPopup = (props) => {
  const [visible, setVisible] = React.useState(false);

  const toggle = () => {
    setVisible(!visible);
  };

  const close = () => {
    setVisible(false);
  };

  let [value, setValue] = useState("");

  const onSelect = (date) => {
    setValue(date.label);
    props.onChange(date);
  };
  const {
    translations: { home },
  } = useContext(LanguageContext);
  const {
    chooseDateTimeHeading,
    nowTwentyMinText,
    todayText,
    tommorowText,
    nowText,
    customText,
    culture,
    closeText,
    selectText,
  } = home.dateTimePicker;

  return (
    <div>
      <div
        onClick={toggle}
        style={{ ...style.container, ...props.containerStyle }}
      >
        <div style={style.button}>
          <i className="fa fa-clock-o" style={style.icon}></i>
          <div style={style.label}>{value || nowText}</div>
        </div>
        <i className="fa fa-angle-down" aria-hidden="true"></i>
      </div>
      <Popup
        transparent
        visible={visible}
        position={popupPosition}
        zIndex={100}
      >
        <AdvanceSelection
          onSelect={onSelect}
          heading={chooseDateTimeHeading}
          nowTwentyMinText={nowTwentyMinText}
          todayText={todayText}
          tommorowText={tommorowText}
          customText={customText}
          onClose={close}
          culture={culture}
          closeText={closeText}
          selectText={selectText}
          {...props}
        />
      </Popup>
    </div>
  );
};

export default DateTimePickerPopup;
