import React, { useState, useContext, useEffect } from "react";
import { Modal } from "reactstrap";
import { LanguageContext } from "../../lang";
import Popup from "./Popup";
import AdvanceSelection from "./components/advance-selection";

var configure = require("react-widgets/lib/configure"),
  moment = require("moment"),
  momentLocalizer = require("./localizer");

configure.setDateLocalizer(momentLocalizer(moment));

const createInitValue = () => {
  let date = new Date();
  return {
    rideType: "ride_now",
    date,
    nlFormat: moment(date),
  };
};

const DateTimePickerPopup = (props) => {
  let [value, setValue] = useState(createInitValue());
  const [modalState, setModalState] = useState(false);

  useEffect(() => {
    props.onChange(value);
  }, [value]);

  const onSelect = (date) => {
    setValue(date);
    if (date.init !== 1) setModalState(false);
  };
  const {
    translations: { home },
  } = useContext(LanguageContext);
  const {
    chooseDateTimeHeading,
    nowTwentyMinText,
    todayText,
    tommorowText,
    nowText,
    customText,
    culture,
    closeText,
    selectText,
  } = home.dateTimePicker;
  const clickHandle = () => {
    setModalState(!modalState);
  };
  const onClose = (date) => {
    if (date.init !== 1) {
      setModalState(false);
    }
  };
  return (
    <>
      <div onClick={clickHandle}>
        {props.renderToggler
          ? props.renderToggler(value.label || nowText)
          : null}
      </div>
      <Modal isOpen={modalState} toggle={clickHandle}>
        <AdvanceSelection
          onSelect={onSelect}
          heading={chooseDateTimeHeading}
          nowTwentyMinText={nowTwentyMinText}
          todayText={todayText}
          tommorowText={tommorowText}
          customText={customText}
          onClose={onClose}
          culture={culture}
          closeText={closeText}
          selectText={selectText}
          {...props}
        />
      </Modal>
    </>
  );
};

export default DateTimePickerPopup;
