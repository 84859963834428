import React, { useState } from "react";
import DateFnsUtils from "@date-io/date-fns"; // choose your lib
import {
  DatePicker,
  TimePicker,
  DateTimePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import netherlandsLocale from "date-fns/locale/nl";
import enLocale from "date-fns/locale/en-US";
import moment from "moment";

const locale = {
  nl: netherlandsLocale,
  en: enLocale,
};

export default ({ type, culture, onChange, minDate, defaultDate }) => {
  const [selectedDate, handleDateChange] = useState(defaultDate || new Date());

  const whenChange = (date) => {
   if (minDate) {
      let d = minDate;
      d.setHours(date.getHours());
      d.setMinutes(date.getMinutes());
      if (moment(d).isBefore(moment())) {
        return alert("Error: Invalid Time");
      }
    } else if (moment(date).isBefore(moment())) {
      return alert("Error: Invalid Time");
    }
    handleDateChange(date);
    onChange(date);
  };

  switch (type) {
    case "time":
      return (
        <MuiPickersUtilsProvider locale={locale[culture]} utils={DateFnsUtils}>
          <TimePicker ampm={false} value={selectedDate} onChange={whenChange} />
        </MuiPickersUtilsProvider>
      );
    default:
      return (
        <MuiPickersUtilsProvider locale={locale[culture]} utils={DateFnsUtils}>
          <DateTimePicker
            value={selectedDate}
            onChange={whenChange}
            disablePast
            ampm={false}
          />
        </MuiPickersUtilsProvider>
      );
  }
};
