import React from "react";
import CloseIcon from "@material-ui/icons/Close";
import RadioChoice from "./radio-selection";
import moment from "moment";

const style = {
  container: {
    minHeight: 320,
    background: "white",
    postion: "relative",
    padding: 10,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    boxShadow: "0px 30px 90px 0px #040415",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 10,
    height: 44,
    borderBottom: "1px solid #eee",
  },
  footer: {
    position: "absolute",
    bottom: 0,
    right: 0,
    left: 0,
    padding: 15,
  },
};

const getTommorow = () => {
  let d = new Date();
  d.setDate(d.getDate() + 1);
  return d;
};

const getNow20Min = () => {
  let d = new Date();
  return new Date(d.getTime() + 20 * 60000);
};

export default class extends React.PureComponent {
  state = {
    selected: "now",
  };

  today = new Date();
  tommorow = getTommorow();
  rideType = "ride_now";
  date = getNow20Min();

  componentDidMount() {
    this.proceed(1);
  }

  onSelect = (selected) => {
    this.setState({ selected });
    if (selected === "now") {
      this.setDefaultDate();
    } else if (selected === "today") {
      this.date = new Date();
    } else if (selected === "tommorow") {
      this.date = getTommorow();
    }
  };

  updateDate = (date) => {
    this.date = date;
  };

  updateTime = (date) => {
    this.date.setHours(date.getHours());
    this.date.setMinutes(date.getMinutes());
  };

  setDefaultDate = () => {
    this.date = getNow20Min();
  };

  proceed = (init) => {
    let date = {
      rideType: this.state.selected === "now" ? "ride_now" : "scheduled_ride",
      date: this.date,
      text: moment(this.date).format("DD-MMM [\n] HH:mm"),
      label:
        this.state.selected === "now"
          ? this.props.nowText
          : moment(this.date).format("DD-MMM [\n] HH:mm"),
      nlFormat: moment(this.date),
      init,
    };
    this.props.onSelect(date);
    this.props.onClose(date);
  };

  render() {
    const { selected } = this.state;
    const {
      heading,
      nowTwentyMinText,
      todayText,
      tommorowText,
      customText,
      onClose,
      culture,
      closeText,
      selectText,
    } = this.props;

    return (
      <div style={style.container}>
        <div>
          <div style={style.header}>
            <div className="choose-date-time-text">{heading}</div>
            <div
              onClick={onClose}
              className="header-text cursor-pointer icon-size-22"
            >
              <CloseIcon style={{ color: "#f26f27" }} />
            </div>
          </div>

          <RadioChoice
            label={nowTwentyMinText}
            value="now"
            onSelect={this.onSelect}
            setTime={this.setTime}
            selected={selected}
            culture={culture}
          />
          <RadioChoice
            label={todayText}
            value="today"
            onSelect={this.onSelect}
            setTime={this.setTime}
            selected={selected}
            date={this.today}
            onChange={this.updateTime}
            culture={culture}
            minDate={new Date()}
          />
          <RadioChoice
            label={tommorowText}
            value="tommorow"
            onSelect={this.onSelect}
            selected={selected}
            setTime={this.setTime}
            date={this.tommorow}
            onChange={this.updateTime}
            culture={culture}
            minDate={getTommorow()}
          />
          <RadioChoice
            label={customText}
            value="custom"
            onSelect={this.onSelect}
            selected={selected}
            onChange={this.updateDate}
            culture={culture}
            //minDate={new Date()}
          />
        </div>
        <div
          className="form-wrap"
          style={{ width: "100%", background: "white" }}
        >
          <div className="ts-form-next-link select-btn" onClick={this.proceed}>
            {selectText}
          </div>
        </div>
      </div>
    );
  }
}
