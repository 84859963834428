import React from "react";
import { Collapse } from "reactstrap";
import RadioButtonUncheckedIcon from "@material-ui/icons/RadioButtonUnchecked";
import RadioButtonCheckedIcon from "@material-ui/icons/RadioButtonChecked";

import DateTime, { TimeOnly } from "../basic";

const style = {
  container: {
    padding: "10px 0px",
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    color: "#000",
    fontSize: "12px",
  },
  pickerContainer: {
    padding: "10px 25px",
  },
};

const Picker = ({ value, ...rest }) => {
  switch (value) {
    case "now":
      return null;
    case "custom":
      return (
        <div style={style.pickerContainer}>
          <DateTime {...rest} />
        </div>
      );
    default:
      return (
        <div style={style.pickerContainer}>
          <TimeOnly {...rest} />
        </div>
      );
  }
};

const RadioChoice = ({
  label,
  value,
  selected,
  onSelect,
  onChange,
  culture,
  ...rest
}) => {
  const Icon =
    selected === value ? RadioButtonCheckedIcon : RadioButtonUncheckedIcon;
  return (
    <>
      <div onClick={onSelect.bind(this, value)} style={style.container}>
        <Icon className="radio-icons" />
        &nbsp;{label}
      </div>
      <Collapse isOpen={selected === value}>
        <Picker value={value} onChange={onChange} culture={culture} {...rest} />
      </Collapse>
    </>
  );
};

export default RadioChoice;
