import React from "react";

const Popup = React.memo(
  ({ position = {}, visible, transparent, children }) => {
    const style = {
      background: transparent ? "#000000cc" : "white",
      border: transparent ? "none" : "2px solid #eee",
      transition: "all 0.2s",
      transform: `scale(${visible ? 1 : 0.6})`,
      opacity: visible ? 1 : 0,
      position: "absolute",
      top: position.top || 0,
      left: position.left || 0,
      zIndex: visible ? 1 : -1,
      right: position.right || 0,
      bottom: position.bottom || 0,
    };

    return <div style={style}>{children}</div>;
  }
);

export default Popup;
